import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  email: '',
  error: null,
};

const RESET_MESSAGE =
  'Please check your email for instructions on how to reset your password.';

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { reset: false, message: null, ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then((data) => {
        this.setState({
          reset: true,
          message: RESET_MESSAGE,
          ...INITIAL_STATE,
        });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.reset !== this.state.reset) {
      setTimeout(() => {
        navigate('/signin');
      }, 3000);
    }
  }

  render() {
    const { email, error, message } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit} className="mt-8">
        <div className="rounded-md shadow-sm">
          <div>
            <input
              aria-label="Email address"
              name="email"
              type="email"
              htmlFor="email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Email address"
              value={email}
              onChange={this.onChange}
            />
          </div>
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo active:bg-red-900 transition duration-150 ease-in-out"
            disabled={isInvalid}
          >
            Reset My Password
          </button>
        </div>

        {error && (
          <p className="font-body text-red-800 text-xs italic mt-6">
            {error.message}
          </p>
        )}

        {message && (
          <p className="font-body text-green-500 text-base italic mt-6">
            {message}
          </p>
        )}

        <div className="mt-6">
          <div className="text-sm text-center leading-5">
            <Link
              to={ROUTES.SIGN_IN}
              className="text-gray-500 hover:text-gray-600 underline focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Back to Sign In Page
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link
      to={ROUTES.PASSWORD_FORGET}
      className="font-body hover:underline"
    >
      Forgot Password?
    </Link>
  </p>
);

export default withFirebase(PasswordForgetForm);

export { PasswordForgetLink };
